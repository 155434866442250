<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    title="关联客户"
    width="1100px"
    top="5vh"
  >
    <div class="wrapper">
      <el-form ref="form" :model="form" inline>
        <el-form-item>
          <sale-area-select-tree
            ref="saleAreaSelectTree"
            @set-sale-select-id="setSaleSelectId"
          ></sale-area-select-tree>
          <!-- <el-cascader
            v-model="area_id"
            :options="areaList"
            clearable
            placeholder="请选择销售区域"
            :props="{ checkStrictly: true, value: 'id', label: 'name' }"
            @change="changeBrandList"
            style="width: 160px"
          ></el-cascader> -->
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.channel_id"
            clearable
            placeholder="请选择渠道"
            style="width: 160px"
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.class_id"
            clearable
            placeholder="请选择客户等级"
            style="width: 160px"
          >
            <el-option
              v-for="item in clientLevelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <client-search
            ref="clientSelect"
            :popper-class="'selectDC'"
            @select-id="selectId"
          ></client-search>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleBatchAdd">批量添加</el-button>
        </el-form-item>
        <el-form-item v-if="showApp">
          <el-checkbox
            v-model="kaiguan"
            :true-label="1"
            :false-label="0"
            @change="setSwitch"
          >
            手机APP下单时仅可选择方案商品
          </el-checkbox>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="loading"
        stripe
        :data="list"
        height="320px"
        @selection-change="handleSelectRow"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finalluColumns"
          :key="index"
          :label="item.label"
          width=""
          :prop="item.prop"
          align="center"
        ></el-table-column>
        <el-table-column
          prop=""
          align="center"
          label="操作"
          fixed="right"
          min-width="80px"
        >
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-units">
        <el-form :model="bottomForm" inline style="margin-top: 20px">
          <el-form-item>
            <el-button @click="handleBatchDeleteRow">批量删除</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>
        <el-pagination
          :current-page="form.pageNo"
          :layout="layout"
          :page-size="form.pageSize"
          :total="totalCount"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button>打印</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
    <batch-add
      ref="batchAdd"
      :client-level-list="clientLevelList"
      :channel-list="channelList"
      :is-set="isSet"
      :type="isSet ? 3 : 1"
      @refresh="fetchData"
      @selectGoods="selectGoods"
    ></batch-add>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { saleAreaList } from '@/api/selectList'
  import {
    getClientLevelList,
    getClientChannelList,
    linkClientIndexList,
    deleteClientRow,
    deleteClientRow2,
  } from '@/api/setPrice'
  import ClientSearch from '@/baseComponents/clientSearch'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import BatchAdd from './batchAdd'
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    name: 'LinkClient',
    components: {
      ClientSearch,
      SaleAreaSelectTree,
      BatchAdd,
    },
    props: {
      isSet: {
        type: Boolean,
        default: false,
      },
      dayin: {
        type: Boolean,
        default: false,
      },
      showApp: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        id: '',
        cust_id: null,
        loading: true,
        area_id: '', //未处理的树  选择区域 id
        areaList: [], //销售区域下拉树
        clientLevelList: [], //客户等级下拉
        channelList: [], //渠道下拉
        showDialog: false,
        form: {
          area_id: '', //区域id
          cust_id: '',
          channel_id: '', //渠道id
          keyword: '',
          class_id: '', //客户等级
          pageNo: 1,
          pageSize: 10,
        },
        bottomForm: {},
        totalCount: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        // 表格
        selectRow: [], // 选中的row
        checkList: ['客户名称', '销售区域', '渠道', '等级'],
        columns: [
          {
            order: 1,
            prop: 'cust_name',
            label: '客户名称',
            width: 'auto',
          },
          {
            order: 2,
            prop: 'area_name',
            label: '销售区域',
            width: '200',
          },
          {
            order: 3,
            prop: 'channel_name',
            label: '渠道',
            width: '180',
          },
          {
            order: 4,
            prop: 'class_name',
            label: '等级',
            width: '160',
          },
        ],
        list: [],
        kaiguan: 0,
      }
    },
    computed: {
      finalluColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => arr.order)
      },
    },
    watch: {
      // 刷新表格数据
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.loading = true
        }
      },
    },
    created() {
      this.getClientLevel()
      this.getChannelList()
    },
    mounted() {},
    methods: {
      // 设置开关
      async setSwitch(val) {
        let data = await postAction('/saleAdmin/scheme/flag-set', {
          scheme_id: this.id,
          flag_goods: val,
        })
        this.$message.success(data.msg)
      },
      // 表格数据获取
      async fetchData() {
        this.loading = true
        var d = null
        if (this.showApp) {
          let kaiguan = await postAction('/saleAdmin/scheme/view', {
            id: this.id,
          })
          this.kaiguan = kaiguan.data.flag_goods
        }
        if (this.isSet) {
          // 打印设置
          if (this.cust_id) {
            d = Object.assign(this.form, { cust_id: this.cust_id })
            let { data, msg, code, totalCount } = await linkClientIndexList(d)
            this.list = data
            this.totalCount = totalCount
            console.log(this.list)
          }
          this.loading = false
        } else {
          d = Object.assign(this.form, { id: this.id })
          let { data, msg, code, totalCount } = await linkClientIndexList(d)
          this.list = data
          this.totalCount = totalCount
          console.log(this.list)
          this.loading = false
        }
      },
      async getClientLevel() {
        let { data, msg, code } = await getClientLevelList()
        if (code == 200) {
          this.clientLevelList = data
        } else {
          this.$message.error(msg)
        }
      },
      async getChannelList() {
        let { data, msg, code } = await getClientChannelList()
        if (code == 200) {
          this.channelList = data
        }
      },
      // setKeyword(val) {
      //   this.form.keyword = val
      // },
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.form.cust_id = val
        } else {
          this.form.cust_id = val
        }
      },
      clearInput() {
        this.form.cust_id = ''
      },
      // 子组件传入 设置销售区域id
      setSaleSelectId(val) {
        this.form.area_id = val
      },
      // 查询
      handleQuery() {
        this.fetchData()
      },

      // 批量添加
      handleBatchAdd() {
        this.$refs.batchAdd.showDialog = true
        this.$refs.batchAdd.id = this.id
      },

      // 表格
      handleSelectRow(val) {
        console.log(val)
        this.selectRow = val
      },
      // 批量删除
      handleBatchDeleteRow() {
        console.log(this.selectRow)
        let selectRow = this.selectRow
        let selectRowId = []
        selectRow.forEach((item) => {
          selectRowId.push(item.cust_id)
        })
        if (selectRow.length !== 0) {
          if (this.dayin) {
            console.log('dayin: ' + selectRowId)
            this.deleteRowF2(selectRowId.join(','))
          } else {
            this.deleteRowF(selectRowId.join(','))
          }
        } else {
          this.$message.error('请勾选数据')
        }
      },
      // 导出
      handleExport() {
        downloadFile(
          '/saleAdmin/scheme/cust-list-export',
          '关联客户.xlsx',
          this.form
        )
      },
      // 删除一个
      handleDeleteRow(index, row) {
        console.log('删除')
        console.log(index, row)
        if (this.dayin) {
          console.log('data2')
          this.deleteRowF2(row.cust_id)
        } else {
          this.deleteRowF(row.id)
        }
      },
      // 打印需要的 删除
      deleteRowF2(ids) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const idsArr = ids.split(',')
            deleteClientRow2({
              ids,
              id: this.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.$emit('refresh')
                const newList = this.list.filter((i, index) => {
                  if (!idsArr.includes(i.cust_id)) {
                    return i
                  }
                })
                this.list = newList
                this.totalCount = newList.length
                // this.list.forEach((i, index) => {
                //   if (idsArr.includes(i.cust_id)) {
                //     this.list.splice(index, 1)
                //   }
                // })
                // this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      // 删除f
      deleteRowF(ids) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteClientRow({
              ids,
              scheme_id: this.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      // 分页
      handleCurrentChange(val) {
        this.loading = true
        this.form.pageNo = val
        this.fetchData()
        this.loading = false
      },
      handleSizeChange(val) {
        this.loading = true

        this.form.pageSize = val
        this.fetchData()
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      selectGoods(rows) {
        // console.log('rows', rows)
        let data = JSON.parse(rows.customer)
        console.log('this.cust_id', this.cust_id)
        let cust_id = this.cust_id == '' ? [] : JSON.parse(this.cust_id)
        data.forEach((i) => {
          cust_id.push(i.cust_id)
        })
        console.log('data', cust_id)

        this.cust_id = cust_id.length == 0 ? '' : JSON.stringify(cust_id)
        // this.fetchData()

        this.$emit('selectGoods', { ...rows, ...{ cust_id: this.cust_id } })

        // this.cust_id = row.cust_id
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .bottom-units {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
</style>
